import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout/en"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"
import NewsGrid from "../components/news/news-grid"
import previewImage from "../images/espais/principal_finestra.jpg"

const IndexPage = (props) => {
	const heroText = "Welcome";
	const textSectionText = "\"In Cal Pere del Maset we want you to enjoy a pleasent moment. We offer you seasonal products, in a luxurious atmosphere. The Alt Penedès, Subirats, Sant Pau d'Ordal. Our home, that we hope you feel it yours. Welcome.\""
	return	(
		  <Layout location={props.location}>
		    <SEO 
		    	title="Homepage" 
		    	lang="en"
		    	description={textSectionText}
		    	image={previewImage}
		    />
		    <HeroImage 
		    	titleText={heroText}
		    	image="vinyards" 
		    />
		    <TextSection textSectionText={textSectionText} />
 		 		<Subsection>
 			    <Link to={"/en/carta"}>
 			    	<Title>Check the new Menu</Title>
 			    </Link>
 			    <StaticQuery
 			      query={graphql`
 			        # Replace this comment with the GraphQL query above
 			        {
 			          allHomepageImagesEnYaml {
 			            edges {
 			              node {
 			                title
 			                image {
 			                	id
 			                  childImageSharp {
 			                    fluid {
 			                      aspectRatio
 			                      originalImg
 			                      # Use gatsby-image later for better user experience
 			                      ...GatsbyImageSharpFluid
 			        }}}}}}}
 			      `}
 			      render={data => (
 			        <ImageGallery
 			          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
 			          images={data.allHomepageImagesEnYaml.edges.map(({ node }) => ({
 			          	id: node.image.id,
 			            ...node.image.childImageSharp.fluid,
 			            caption: `${node.title}`,
 			          }))}
 			        />
 			      )}
 			    />
 			  </Subsection>

		 		<Subsection>
			    <Link to={"/en/noticies"}>
			    	<Title>Check last News</Title>
			    </Link>
			    <NewsGrid numberPreviews="2" />
			  </Subsection>
		  </Layout>
		)
}

const Title = styled.h1`
	text-align: center;
`

const Subsection = styled.div`
	margin-top: 60px;
	a {
	  text-decoration: none;
	  color: #000;
	  font-weight: bold;
	}
`

export default IndexPage
